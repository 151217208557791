export default [
  {
    path: '/teacher/home', name: 'TeacherHome',
    component: _ => import(/* webpackChunkName: "teacher-home" */ '../views/TeacherHome.vue'),
    meta: {
      active: '/teacher/home',
      requireAuth: true,
      pageRole: 'UserTeacher'
    }
  },
  {
    path: '/teacher/student/:id', name: 'TeacherStudent',
    component: _ => import(/* webpackChunkName: "teacher-student" */ '../views/TeacherStudent.vue'),
    meta: {
      requireAuth: true,
      pageRole: 'UserTeacher'
    }
  },
  {
    path: '/teacher/mocks', name: 'TeacherMocks',
    component: _ => import(/* webpackChunkName: "teacher-mocks" */ '../views/TeacherMocks.vue'),
    meta: {
      active: '/teacher/mocks',
      requireAuth: true,
      pageRole: 'UserTeacher'
    }
  },
  {
    path: '/teacher/mock/:paperId/:studentId?', name: 'TeacherStudentMock',
    component: _ => import(/* webpackChunkName: "teacher-student-mock" */ '../views/TeacherStudentMock.vue'),
    meta: {
      requireAuth: true,
      pageRole: 'UserTeacher'
    }
  },
  {
    path: '/teacher/bookmark', name: 'TeacherBoomark',
    component: _ => import(/* webpackChunkName: "teacher-mocks" */ '../views/TeacherBookmark.vue'),
    meta: {
      active: '/teacher/bookmark',
      requireAuth: true,
      pageRole: 'UserTeacher'
    }
  },
]