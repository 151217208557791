export default {
  "firebaseApp": {
    "apiKey": "AIzaSyCMRrMTQRz5qUCjUvjNZ2ZTZqUcTDNUbI0",
    "authDomain": "oneed-site.firebaseapp.com",
    "projectId": "oneed-site",
    "storageBucket": "oneed-site.appspot.com",
    "messagingSenderId": "558791316197",
    "appId": "1:558791316197:web:7fbe30dcfc8ba57b12fa0c",
    "measurementId": "G-60G0SSF9KZ"
  },
  "route": {
    "studentHome": "StudentHome",
    "teacherHome": "TeacherHome"
  },
  "graphqlClient": {
    "url": "https://87v6d502h7.execute-api.ap-southeast-1.amazonaws.com/index"
  },
  "contentClient": {
    "signerUrl": "https://84kjewupa1.execute-api.ap-southeast-1.amazonaws.com/"
  },
  "version": "1.1.2.8"
}