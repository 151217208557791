import { createRouter, createWebHistory } from 'vue-router'

import { handleLogin, logout } from '@/util/login'
import { store as appStore } from '@/stores/app/app.js'
import { log } from '@/util/misc'
import { query } from '@/util/graphql-client.js'

import studentRouter from './student-router'
import teacherRouter from './teacher-router'
import testRouter from './test-router'
import config from '@/config'
import { loadUserSessionData, loadIdentities } from '@/stores/app/login'

var routes = [
  {
    path: '/', name: 'Home',
    meta: { requireAuth: true, isHome: true }
  },
  {
    path: '/login', name: 'Login',
    component: _ => import(/* webpackChunkName: "login" */ '../views/Login.vue'),
    meta: { requireAuth: false }
  },
  {
    path: '/logout', name: 'Logout',
    meta: { requireAuth: false },
    beforeEnter(to, from, next) {
      logout(next)
    }
  },
  ...studentRouter,
  ...teacherRouter,
  ...testRouter
]

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes
})

function checkPageRole(role, pageRole) {
  if (pageRole == '*') {
    return true
  } else {
    return pageRole == role
  }
}

function getDefaultUserPageName(role) {
  let lookup = {
    UserTeacher: config.route.teacherHome,
    UserStudent: config.route.studentHome,
  }
  if (lookup[role] != null) {
    return lookup[role]
  } else {
    log(`role ${role} has unknown homepage`)
    return "Logout"
  }
}

function updateRoute(to, from, next) {
  let userRole = appStore?.currentIdentity.__typename
  if (to.meta.isHome || !checkPageRole(userRole, to.meta.pageRole)) {
    log('redirect to default user homepage')
    next({ name: getDefaultUserPageName(userRole) })
  } else {
    next()
  }
}

router.beforeEach((to, from, next) => {
  loadUserSessionData(appStore)
  handleLogin(to, from, next, async (user) => {
    appStore.firebaseUser = user
    query('{version}')
      .then(data =>
        console.log(`server-version:${data.version}`)
      )
    console.log(appStore)
    await loadIdentities(appStore)
    log('updated user identities')
    updateRoute(to, from, next)
  })
})

export default router
