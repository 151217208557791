import { createApp } from 'vue'
import dateFormat from 'dateformat'

import Vue3Toasity from 'vue3-toastify';
import 'vue3-toastify/dist/index.css';

import router from './router'
import config from './config'

import App from './App.vue'

const app = createApp(App)
app.use(router)
  .use(Vue3Toasity)
  .mount('#app')

const $timezoneOffset = 8
app.config.globalProperties.$timezoneOffset = $timezoneOffset

const $interfaceLang = 'zh_hk'

const $t = (lang, enWords, zhWords) => (lang == 'zh_hk' ? zhWords : enWords)

app.config.globalProperties.$interfaceLang = $interfaceLang

app.config.globalProperties.$setInterfaceLang = (lang) => {
  app.config.globalProperties.$interfaceLang = lang
}

app.config.globalProperties.$t = $t

app.config.globalProperties.$displayDate = function (value) {
  if (value == null) {
    return '-'
  }
  let localTimeValue = new Date(Number(value) + $timezoneOffset * 3600 * 1000)
  return $t(
    $interfaceLang,
    dateFormat(localTimeValue, 'GMT:dd mmm yyyy'),
    dateFormat(localTimeValue, 'GMT:dd-mm-yyyy')
  )
}

app.config.globalProperties.$displayGrade = x => {
  let grades = ['≤1', '≤1', '2', '3', '4', '5', '5*', '5**']
  return grades[x]
}


const version = config.version
console.log(`version: ${version}`)
