import { initializeApp } from 'firebase/app'
import { getAuth } from 'firebase/auth'

import config from '../config'

import { log } from './misc.js'

export function handleLogin(to, from, next, callback) {
  if (to.meta.requireAuth == true) {
    initializeApp(config.firebaseApp)
    const auth = getAuth()
    auth.onAuthStateChanged(async function (user) {
      if (user) {
        log('user logged in')
        try {
          await callback(user)
        } catch (e) {
          log(e)
          sessionStorage.clear()
          next({ name: 'Login' })
        }
      } else {
        sessionStorage.clear()
        next({ name: 'Login' })
      }
    })
  } else {
    next()
  }
}

export function logout(next) {
  initializeApp(config.firebaseApp)
  getAuth().signOut().then(_ => {
    sessionStorage.clear()
    next({ name: 'Login' })
  })
}