import { createClient, dedupExchange, cacheExchange, fetchExchange } from '@urql/core';
import { store as appStore } from '@/stores/app/app.js'

import config from '@/config.js'

let token = null

export const graphqlClient = createClient({
  url: config.graphqlClient.url,
  exchanges: [
    dedupExchange,
    cacheExchange,
    fetchExchange
  ],
  requestPolicy: 'network-only',
  fetchOptions: () => {
    return {
      headers: { authorization: token ? `Bearer ${token}` : '' },
    };
  },
});

export async function query(graphqlQuery, variables, context) {
  token = await appStore.firebaseUser?.getIdToken()
  return await graphqlClient.query(graphqlQuery, variables, context)
    .toPromise()
    .then(res => res?.data)
}

export function readQuery(graphqlQuery, variables) {
  let result = graphqlClient.readQuery(graphqlQuery, variables)
  return result?.data
}

export async function mutate(graphqlQuery, variables) {
  token = await appStore.firebaseUser?.getIdToken()
  return await graphqlClient.mutation(graphqlQuery, variables)
    .toPromise()
    .then(res => res?.data)
}