export default [
  {
    path: '/student/home', name: 'StudentHome',
    component: _ => import(/* webpackChunkName: "student-home" */ '@/views/StudentHome.vue'),
    meta: {
      active: '/student/home',
      requireAuth: true,
      pageRole: 'UserStudent'
    }
  },
  {
    path: '/student/mock/:id', name: 'StudentMock',
    component: _ => import(/* webpackChunkName: "student-mock" */ '../views/StudentMock.vue'),
    meta: {
      requireAuth: true,
      pageRole: 'UserStudent'
    }
  },
  {
    path: '/student/bookmark', name: 'StudentBookmark',
    component: _ => import(/* webpackChunkName: "student-bookmark" */ '../views/StudentBookmark.vue'),
    meta: {
      active: '/student/bookmark'
    }
  },
  {
    path: '/student/mock-result/:id', name: 'StudentMockResult',
    component: _ => import(/* webpackChunkName: "student-mock-result" */ '../views/StudentMockResult.vue'),
    meta: {
      requireAuth: true,
      pageRole: 'UserStudent'
    }
  },
  {
    path: '/student/mock-report/:id', name: 'StudentMockReport',
    component: _ => import(/* webpackChunkName: "student-mock-result" */ '../views/StudentMockReport.vue'),
    meta: {
      requireAuth: true,
      pageRole: 'UserStudent'
    }
  },
  {
    path: '/student/booster', name: 'StudentBooster',
    component: _ => import(/* webpackChunkName: "student-report" */ '../views/StudentBooster.vue'),
    meta: {
      active: '/student/booster',
      requireAuth: true,
      pageRole: 'UserStudent'
    }
  },
  {
    path: '/student/bookmark', name: 'StudentBookmark',
    component: _ => import(/* webpackChunkName: "student-report" */ '../views/StudentBookmark.vue'),
    meta: {
      active: '/student/bookmark',
      requireAuth: true,
      pageRole: 'UserStudent'
    }
  },
  {
    path: '/student/performance', name: 'StudentPerformance',
    component: _ => import(/* webpackChunkName: "student-report" */ '../views/StudentPerformance.vue'),
    meta: {
      active: '/student/performance',
      requireAuth: true,
      pageRole: 'UserStudent'
    }
  }
]