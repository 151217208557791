import { reactive } from 'vue'

export function newPaper() {
  return {
    id: null,
    scheduleTime: null,
    indexNo: null,
    timeLimit: null,
    startTime: null,
    endTime: null,
    numQuestion: null,
    numQuestionCorrectPercentiles: [],

    perfGrade: null,
    perfScores: [null, null, null, null, null],
    numCorrectQuestion: null,
    medianTimePerQuestion: null,
    suggestedTopics: [],

    isScheduleTimeChangeable: null,
    revealSolutionTime: null,

    studentStats: []
  }
}

export function newStudent() {
  return {
    id: null,
    idCode: null,
    systemName: null,
    perfGrade: null,
  }
}

function newStore() {
  return {
    firebaseUser: {},
    currentUserId: null,
    currentUsergroupId: null,
    identities: [],
    currentIdentity: {},

    showModal: { name: '', params: {} },
    useranswers: [],

    currentUseranswerIndexNo: 0,
    revealUseranswersSolutionsTime: Date.now(),
    revealUseranswersSolutions: false,
    revealUseranswersCorrectAnswer: false,

    useranswerTimerLength: 1000,

    scheduleByDates: [],
    loadedSchedules: false,

    userStudentStats: {
      currentUser: {
        perfGrade: null,
        medianTimePerMockQuestion: null,
        numCorrectMockQuestion: null,
        numSubmitMockQuestion: null,
        perfScores: [null, null, null, null, null],
        topics: [],
        papers: [],
        suggestedTopics: [],
      },
      globalRankings: [],
      schoolRankings: [],
      usergroupRankings: [],
      globalMockCorrectRatePercentiles: [],
      loadedPerformance: false,
      loadedRanking: false
    },
    paper: newPaper(),
    paperMocks: [],
    students: [],
    student: {},
    studentPassRate: 50,

    answerSheetType: false,

    isSettingUp: false,
    isSubmittingTimers: false,
    isSubmittingMock: false,

    toasts: []
  }
}

export const store = reactive(newStore())

export function resetStore() {
  Object.assign(store, {
    ...newStore(),
    firebaseUser: store.firebaseUser,
    currentUserId: store.currentUserId,
    currentUsergroupId: store.currentUsergroupId,
    identities: store.identities,
    currentIdentity: store.currentIdentity
  })
}

export function newUseranswer() {
  return {
    id: null,
    indexNo: null,
    qtemplate: {
      id: null,
      isBookmarked: null
    },
    displayQuestion: ({
      id: null,
      key: null,
      solutionKey: null,
      isRetracted: null,
      __typename: null
    }),
    sample: null,
    displayPerm: [],
    displayAnswerIndexNo: null,
    displayCorrectAnswerIndexNo: null,
    displayInMockAnswerPercentages: [],
    retryQuestion: ({
      id: null,
      key: null,
      solutionKey: null,
      isRetracted: null,
      __typename: null
    }),
    retrySample: null,
    retryPerm: [],
    retryAnswerIndexNo: null,
    retryCorrectAnswerIndexNo: null,
    solutions: [],
    solutionVideoData: {},
    timerSession: '' + Date.now(),
    timerTime: 0,
    isSettingUp: false,
    isLoadingSample: false,
    isLoadingRetrySample: false,
    isUpdatingBookmark: false
  }
}

export function showModal({ name, params }) {
  Object.assign(store.showModal, { name, params })
}