export function log(...vars) {
  if (process.env.NODE_ENV !== 'production') {
    console.log(...vars)
  }
}

export function tryParse(JSONstring) {
  try {
    return JSON.parse(JSONstring)
  } catch (e) { }
}