import { tryParse } from '@/util/misc'
import { query, mutate } from '@/util/graphql-client.js'

export function loadUserSessionData(store) {
  Object.assign(store, tryParse(sessionStorage.app))
}

export function saveUserSessionData(store) {
  sessionStorage.app = JSON.stringify({
    currentUserId: store.currentUserId,
    currentUsergroupId: store.currentUsergroupId
  })
}

export function setCurrentIdentity(store, identity) {
  store.currentIdentity = store.identities.find(x => x.id == identity.id)
  Object.assign(store.currentIdentity, identity)
  store.currentUserId = store.currentIdentity?.id
  // set default current usergroupId
  if (store.currentUsergroupId == null && store.currentIdentity?.usergroups != null) {
    store.currentUsergroupId = store.currentIdentity?.usergroups[0].id
  }
}

export function setCurrentUsergroup(store, usergroupId) {
  let usergroups = store.currentIdentity?.usergroups
  if (usergroups != null && usergroups.findIndex(x => x.id == usergroupId) >= 0) {
    store.currentUsergroupId = usergroupId
    saveUserSessionData(store)
  }
}

// load identities
import getMyIdentitiesGraphql from './getMyIdentities.graphql'


function getDefaultCurrentIdentity(identities) {
  let allowedIdentities = ['UserTeacher', 'UserStudent']
  identities = identities?.filter(x => allowedIdentities.includes(x.__typename))
  if (identities?.length > 0) {
    let priorities = ['UserTeacher', 'UserStudent']
    let identity = {}
    for (let p of priorities) {
      let x = identities.filter(x => x.__typename == p)
      if (x.length > 0) {
        identity = x[0]
        break;
      }
    }
    if (identity?.id != null) {
      return (identity)
    } else {
      throw Error('no available identity')
    }
  } else {
    throw Error('no available identity')
  }
}

export async function loadIdentities(store) {
  await query(getMyIdentitiesGraphql)
    .then(
      ({ myIdentities: identities }) => {
        let currentIdentity = identities.find(x => x.id == store.currentUserId)
        if (currentIdentity == null) {
          currentIdentity = getDefaultCurrentIdentity(identities)
        }
        store.identities = identities
        setCurrentIdentity(store, currentIdentity)
      }
    )
}

// update lang
import updateInterfaceLangGraphql from './updateInterfaceLang.graphql'

export async function updateLang(store, lang) {
  let currentUserId = store.currentUserId
  let interfaceLang = lang
  let user = await mutate(
    updateInterfaceLangGraphql, {
    currentUserId, interfaceLang
  }).then(res => res.updateMyInterfaceLang.user)
  setCurrentIdentity(store, user)
}

// update student account
import updateMyStudentAccountGraphql from './updateMyStudentAccount.graphql'

export async function updateMyStudentAccount(store, avatarName, displayName) {
  let currentUserId = store.currentUserId
  let user = await mutate(updateMyStudentAccountGraphql, {
    currentUserId, displayName, avatarName
  }).then(res => res.updateMyStudentAccount.user)
  setCurrentIdentity(store, user)
}