export default [
  {
    path: '/test', name: 'Test',
    component: _ => import(/* webpackChunkName: "test" */ '../views/test/Test.vue'),
  },
  {
    path: '/test-student', name: "TestStudent",
    component: _ => import(/* webpackChunkName: "test-student" */ '../views/test/TestStudent.vue'),
    meta: {
      requireAuth: true,
      pageRole: 'UserStudent'
    }
  },
  {
    path: '/test-teacher', name: "TestTeacher",
    component: _ => import(/* webpackChunkName: "test-teacher" */ '../views/test/TestTeacher.vue'),
    meta: {
      requireAuth: true,
      pageRole: 'UserStudent'
    }
  }
]